var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[(this.$route.query.isAddAsset)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1):_vm._e(),_c('b-tabs',[_c('b-tab',{attrs:{"title":"Add Asset","active":""}},[_c('AssetWizard',{attrs:{"users":_vm.users,"assetTypes":_vm.assetTypes,"getScanId":_vm.getScanId,"getScanNameById":_vm.getScanNameById,"getScheduleOptions":_vm.getScheduleOptions,"load":_vm.load,"getScanOptionsAndSetData":_vm.getScanOptionsAndSetData,"loadAssetType":_vm.loadAssetType,"onOrgChange":_vm.onOrgChange}})],1),_c('b-tab',{attrs:{"title":"Upload Assets"}},[_c('div',{staticClass:"mb-2"},[_c('h6',[_vm._v(" Sample file : "),_c('a',{attrs:{"href":"files/sample_asset_upload_file.xlsx","download":""}},[_vm._v(" Click here to download sample file. ")])])]),_c('validation-observer',{ref:"AssetUploadForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.uploadAsset($event)}}},[(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"--Select Organization--","options":_vm.organizations,"autocomplete":"","reduce":function (organization) { return organization.tenant_uid; },"disabled":""},on:{"input":_vm.onOrgChange},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3674462525)})],1):_vm._e(),(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Owner","label-for":"Owner"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Owner")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,false,3633470372)},[_c('validation-provider',{attrs:{"name":"asset_owner_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.users,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_owner_id),callback:function ($$v) {_vm.asset_owner_id=$$v},expression:"asset_owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,925388404)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Asset Group","label-for":"Asset Group"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Asset Group")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")]),_c('b-button',{staticClass:"ml-1",staticStyle:{"padding":"0.186rem 1rem !important"},attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.gotoCreateGroup()}}},[_vm._v("+ Add Asset Group")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Asset Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assetGroups,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_group),callback:function ($$v) {_vm.asset_group=$$v},expression:"asset_group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Upload File","label-for":"File"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Upload File")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('b-form-file',{ref:"file",attrs:{"state":Boolean(_vm.asset_file),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload()}},model:{value:(_vm.asset_file),callback:function ($$v) {_vm.asset_file=$$v},expression:"asset_file"}}),_c('div',{staticClass:"mt-3"},[_vm._v(" Selected file: "+_vm._s(_vm.asset_file ? _vm.asset_file.name : "")+" ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.asset_group === null || _vm.asset_file === null,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Upload")])])],1)],1)],1)],1),_c('b-modal',{ref:"modal-danger",attrs:{"id":"modal-danger","ok-only":"","ok-variant":"danger","modal-class":"modal-danger","centered":"","title":"Asset Group !","size":"lg"},on:{"ok":function($event){return _vm.scanAsset()}}},[_c('b-card-text',[_vm._v(" Asset group not Selected ! ")])],1),_c('b-modal',{ref:"modal-schedule-scan",attrs:{"title":"Schedule Scan","hide-footer":"","size":"lg"}},[_c('validation-observer',{ref:"ScanScheduleForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.scheduleScan($event)}}},[_c('b-form-group',{attrs:{"label":"Schedule","label-for":"Schedule"}},[_c('validation-provider',{attrs:{"name":"schedule_selected","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.schedule_scan_data,"state":errors.length > 0 ? false : null},model:{value:(_vm.schedule_scan_selected),callback:function ($$v) {_vm.schedule_scan_selected=$$v},expression:"schedule_scan_selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[(_vm.loadingSchedule)?_c('span',[_vm._v("Please wait")]):_c('span',[_vm._v("Schedule")])])],1)]}}])})],1),_c('b-modal',{ref:"modal-new-network-location",attrs:{"title":"Create New Network Location","hide-footer":"","size":"lg"}},[_c('AddNetworkLocation',{attrs:{"redirectNetwork":_vm.redirectNetwork,"closeSidebar":_vm.closeNetworkModal}})],1),_c('b-modal',{ref:"modal-new-group",attrs:{"title":"Create New Group","hide-footer":"","size":"lg"}},[_c('AddAssetGroup',{attrs:{"is_asset_group":_vm.is_asset_group,"parentCallBack":_vm.closeModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }