<template>
  <b-row class="">
    <b-col cols="12">
      <div>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          layout="vertical"
          finish-button="Submit"
          back-button-text="Previous"
          class="wizard-vertical mb-3"
          v-model="activeTab"
          ref="wizard"
        >
          <!-- asset datails tab -->
          <tab-content title="Add Asset">
            <b-row>
              <b-col cols="12" class="">
                <b-alert show variant="warning">
                  <div class="alert-body font-italic">
                    <feather-icon icon="InfoIcon" class="mr-50 mt-25" />

                    If you're adding a CIDR asset, please
                    <span
                      class="font-weight-bold text-success cursor-pointer"
                      @click="
                        $router.push({
                          path: '/Discovery',
                        })
                      "
                    >
                      <span>click here</span>.</span
                    >
                  </div>
                </b-alert>
              </b-col>
              <b-col cols="12" class="ml-auto mr-auto">
                <b-tabs>
                  <b-tab title="Internet Facing Asset" active>
                    <b-card class="border-primary rounded">
                      <b-row>
                        <b-col md="12">
                          <b-form-group v-if="is_staff" label="Organization">
                            <v-select
                              v-model="organization"
                              label="name"
                              placeholder="--Select Organization--"
                              :options="organizations"
                              @input="onOrgChange"
                              autocomplete
                              :reduce="
                                (organization) => organization.tenant_uid
                              "
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group v-if="is_staff" label="Owner">
                            <template #label>
                              <span>Owner</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="asset_owner_id"
                              rules="required"
                            >
                              <b-form-select
                                v-model="asset_owner_id"
                                :options="users"
                                :state="errors.length > 0 ? false : null"
                              ></b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Asset Name"
                            label-for="v-username"
                          >
                            <template #label>
                              <span>Asset Name</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <b-form-input
                              id="asset_name"
                              v-model="asset_name_external"
                              name="asset_name"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="IP or Domain Name"
                            label-for="v-url"
                          >
                            <template #label>
                              <span>IP or Domain Name</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <b-form-input
                              id="v-url"
                              v-model="assetURLExternal"
                              name="assetURL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <!-- Asset Type-->
                          <b-form-group label="Asset Type">
                            <template #label>
                              <span>Asset Type</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="asset_type"
                              rules="required"
                            >
                              <b-form-select
                                v-model="asset_type_external"
                                :options="assetTypes"
                                :state="errors.length > 0 ? false : null"
                              ></b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="12 d-flex justify-content-center">
                          <b-button
                            variant="primary"
                            size="sm"
                            @click="addAssetInternetFacing()"
                            :disabled="
                              isDisableInternetFacing ||
                              asset_name_external === '' ||
                              assetURLExternal === '' ||
                              asset_type_external === ''
                            "
                          >
                            <span v-if="isLoadingAssetInternetFacing"
                              ><b-spinner type="border" small></b-spinner>
                              Please wait</span
                            >
                            <span v-else>Add</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                  <b-tab title="Internal Asset" lazy>
                    <b-card class="border-primary rounded">
                      <b-row v-if="checkFeature === true">
                        <b-col md="12">
                          <b-form-group v-if="is_staff" label="Organization">
                            <v-select
                              v-model="organization"
                              label="name"
                              placeholder="--Select Organization--"
                              :options="organizations"
                              @input="onOrgChange"
                              autocomplete
                              :reduce="
                                (organization) => organization.tenant_uid
                              "
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group v-if="is_staff" label="Owner">
                            <template #label>
                              <span>Owner</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="asset_owner_id"
                              rules="required"
                            >
                              <b-form-select
                                v-model="asset_owner_id"
                                :options="users"
                                :state="errors.length > 0 ? false : null"
                              ></b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Asset Name"
                            label-for="v-username"
                          >
                            <template #label>
                              <span>Asset Name</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <b-form-input
                              id="asset_name"
                              v-model="asset_name"
                              name="asset_name"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="IP or Domain Name"
                            label-for="v-url"
                          >
                            <template #label>
                              <span>IP or Domain Name</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <b-form-input
                              id="v-url"
                              v-model="assetURL"
                              name="assetURL"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- <b-col md="12">
                          <b-form-group label="Asset Exposure">
                            <template #label>
                              <span>Asset Exposure</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="asset_group_type"
                              rules="required"
                            >
                              <b-form-select
                                v-model="asset_group_type"
                                :options="assetGroupTypes"
                                :state="errors.length > 0 ? false : null"
                              ></b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- v-if="asset_group_type === 'internal'" -->
                        <b-col md="12">
                          <b-form-group>
                            <template #label>
                              <span>Network Location</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                              <b-button
                                class="ml-1"
                                style="padding: 0.186rem 1rem !important"
                                @click="gotoCreateNetwork()"
                                variant="outline-success"
                                size="sm"
                                >+ Add Network Location</b-button
                              >
                            </template>
                            <v-select
                              v-model="networkLocation"
                              label="text"
                              placeholder="--Select Network Location--"
                              :options="networkLocationOptions"
                              autocomplete
                              :reduce="
                                (networkLocation) => networkLocation.value
                              "
                              :clearable="false"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <!-- Asset Type-->
                          <b-form-group label="Asset Type">
                            <template #label>
                              <span>Asset Type</span>
                              <span
                                class="text-danger font-weight-bold h5"
                                style="margin-left: 3px"
                                >*</span
                              >
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="asset_type"
                              rules="required"
                            >
                              <b-form-select
                                v-model="asset_type"
                                :options="assetTypes"
                                :state="errors.length > 0 ? false : null"
                              ></b-form-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-alert show variant="warning">
                            <div class="alert-body font-italic">
                              <feather-icon
                                icon="InfoIcon"
                                class="mr-50 mt-25"
                              />

                              To add internal scanners,
                              <span v-if="!isLoadMail">please </span>
                              <span
                                class="font-weight-bold text-success cursor-pointer"
                                @click="sendMail()"
                                :class="{ disabled: disableMail }"
                              >
                                <span v-if="isLoadMail"> Please Wait</span>
                                <span v-else>click here</span></span
                              >
                              <!-- <a
                                href="https://secusy.ai/contact-us/"
                                class="font-weight-bold text-success cursor-pointer"
                                target="_blank"
                              >
                                Click here
                              </a> -->
                              to 'Submit Request'. Our support team will then
                              contact you to configure the internal scanner.
                            </div>
                          </b-alert>
                        </b-col>
                        <b-col md="12 d-flex justify-content-center">
                          <b-button
                            variant="primary"
                            size="sm"
                            @click="addAssetInternal()"
                            :disabled="
                              isDisable ||
                              asset_name === '' ||
                              assetURL === '' ||
                              asset_type === ''
                            "
                          >
                            <!-- asset_group_type === '' || -->
                            <span v-if="isLoadingAsset"
                              ><b-spinner type="border" small></b-spinner>
                              Please wait</span
                            >
                            <span v-else>Add</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col md="12">
                          <b-alert show variant="warning">
                            <div class="alert-body font-italic">
                              <feather-icon
                                icon="InfoIcon"
                                class="mr-50 mt-25"
                              />

                              To add internal assets, please subscribe to our
                              internal scans feature or
                              <span
                                class="font-weight-bold text-success cursor-pointer"
                                @click="sendMail()"
                                :class="{ disabled: disableMail }"
                              >
                                <span v-if="isLoadMail"> Please Wait</span>
                                <span v-else>click here</span></span
                              >
                              to submit a ticket to us. Our support team will
                              contact you shortly to help you on this.
                            </div>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </tab-content>

          <!-- verify info tab -->
          <!-- <tab-content title="Verify Domain">
            <b-row>
              <b-col md="12">
                <b-tabs ref="verficationTabs">
                  <b-tab title="File Verification" active>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">By manual file upload</h5>
                        <small class="text-muted"
                          >Verify domain ownership by manual file upload</small
                        >
                      </b-col>
                      <b-col cols="12">
                        <b-card class="border-primary rounded">
                          <p>
                            Download the file on your computer using the link
                            provided below Place the text file in the root of
                            the website directory. Need help? Check out
                            <a href="">this article</a>
                          </p>
                          <p>
                            Secusy will try to access the file at the following
                            location:
                          </p>
                          <b-form-input v-model="fileVerification" disabled />
                          <p class="text-muted">
                            Click on the Verify domain button to complete the
                            domain verification process
                          </p>
                          <div class="d-flex justify-content-center">
                            <a
                              href="_cxmth847zhtbiov3fgas0cv1h8cpw0af.txt"
                              download
                            >
                              <b-button variant="primary" size="sm">
                                Download
                              </b-button>
                            </a>
                          </div>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="12 d-flex justify-content-center">
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          @click="gotoverficationTabs()"
                        >
                          Skip
                        </b-button>
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class="ml-1"
                          @click="getfileVerification()"
                        >
                          Verify
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="DNS Verification" lazy>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">
                          Add the below TXT details as your DNS entry
                        </h5>
                      </b-col>
                      <b-col cols="12">
                        <b-card class="border-primary rounded">
                          <b-row>
                            <b-col md="12">
                              <b-form-group label="Type" label-for="v-txt">
                                <b-form-input
                                  id="v-txt"
                                  placeholder="TXT"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                              <b-form-group label="Name" label-for="v-name">
                                <b-form-input
                                  id="v-name"
                                  disabled
                                  v-model="recod_name"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                              <b-form-group label="Value" label-for="v-value">
                                <b-form-input
                                  id="v-value"
                                  disabled
                                  v-model="expected_value"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="12 d-flex justify-content-center">
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          @click="gotoverficationTabs()"
                        >
                          Skip
                        </b-button>
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class="ml-1"
                          @click="getDNSverification()"
                        >
                          Verify
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="HTML Verification" lazy>
                    <b-row>
                      <b-col cols="12" class="mb-2">
                        <h5 class="mb-0">
                          Copy and paste the below snippet within the head tag
                          in the HTML of your site's homepage
                        </h5>
                      </b-col>
                      <b-col cols="12">
                        <b-card class="border-primary rounded">
                          <b-row>
                            <b-col md="12">
                              <b-form-group label-for="v-meta">
                                <b-form-input
                                  id="v-meta"
                                  disabled
                                  v-model="HTMLVerification"
                                />
                              </b-form-group>
                              <p class="text-muted">
                                Save and confirm that tag is present in the live
                                page
                              </p>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                      <b-col cols="12" md="12 d-flex justify-content-center">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class=""
                          @click="getHTMLverification()"
                        >
                          Verify
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </tab-content> -->

          <!-- start test -->
          <tab-content title="Start Test">
            <b-row>
              <b-col cols="12" class="mb-2">
                <b-card class="border-primary rounded">
                  <b-row>
                    <b-col cols="12" class="mb-2 d-flex justify-content-center">
                      <feather-icon
                        icon="ShieldIcon"
                        class="ml-50 text-primary"
                        size="50"
                      />
                    </b-col>
                    <b-col cols="12" class="mb-2 d-flex justify-content-center">
                      <p class="text-primary">You can start the test now</p>
                    </b-col>
                    <b-col cols="12" class="d-flex justify-content-center">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="startTest()"
                        :disabled="disableBtn"
                      >
                        <span v-if="isloading"
                          ><b-spinner type="border" small></b-spinner> Please
                          wait</span
                        >
                        <span v-else> Start Test</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
        <!-- <button @click="goToLastTab">Go to Last Tab</button> -->
      </div>
    </b-col>
    <b-modal
      ref="modal-new-network-location"
      title="Create New Network Location"
      hide-footer
      size="lg"
    >
      <AddNetworkLocation
        :redirectNetwork="redirectNetwork"
        :closeSidebar="closeNetworkModal"
      />
    </b-modal>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BSpinner,
  BFormSelect,
  BModal,
  BAlert,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import AddNetworkLocation from "../../NetworkLocation/add.vue";
export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BTab,
    BTabs,
    BSpinner,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BModal,
    AddNetworkLocation,
    BAlert,
  },
  data() {
    return {
      activeTab: 0,
      is_staff: this.$store.state.app.user.is_staff,
      assetURL: "",
      asset_name: "",
      asset_owner_id: "",
      scanners: [],

      fileVerification: "_cxmth847zhtbiov3fgas0cv1h8cpw0af.txt",
      fVerification: "",
      DNSVerification: "",
      recod_name: "_cKg6Xp9wDq2Rv4Tc3Nj8Ym5Lh1FbGsZx",
      expected_value: "_k2Pb8cN5rDqX9kZj7Mv3Fg6TtWxL1hYsR",
      HTMLVerification:
        '<meta name="_j5aB8Rt2Yn6qXpDg9Ju4F7h3KcV2xLwNt" , signature="_c9W3cJ5mR8xQ1vF6zK2nD7pB4hT0gXyLw" />',
      org_id: this.$store.state.app.organizationId,
      assetId: null,
      is_verified: false,
      isloading: "",
      disableBtn: false,

      //   asset section new
      selectedOrganization: this.$store.state.app.org_id,
      // organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organizations: this.$store.state.app.organizations,
      users: [{ value: null, text: "--Select Owner--" }],
      user: "",
      asset_type: "",
      assetGroupTypes: [
        { value: null, text: "--Select Asset Exposure--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "Internet Facing" },
      ],
      asset_group_type: "",
      isLoadingAsset: false,
      isLoadingAssetInternetFacing: false,
      networkLocationOptions: [],
      networkLocation: null,
      redirectNetwork: true,
      isDisable: false,
      isDisableInternetFacing: false,
      assetURLExternal: "",
      asset_name_external: "",
      asset_type_external: "",
      isLoadMail: false,
      disableMail: false,
      checkFeature: "",
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    assetTypes: {
      type: Array,
      required: true,
    },
    getScanId: {
      type: Function,
      required: true,
    },
    getScanNameById: {
      type: Function,
      required: true,
    },
    getScheduleOptions: {
      type: Function,
      required: true,
    },
    load: {
      type: Function,
      required: true,
    },
    getScanOptionsAndSetData: {
      type: Function,
      required: true,
    },
    loadAssetType: {
      type: Function,
      required: true,
    },
    onOrgChange: {
      type: Function,
      required: true,
    },
  },
  computed: {
    organization() {
      return this.organizations.find(
        (org) => org.tenant_uid === this.$store.state.app.org_id
      );
    },
    isOppositeCheckboxDisabled() {
      return (oppositeCheckboxState) => {
        return oppositeCheckboxState;
      };
    },
    selectedOrganizationRefid() {
      if (this.organization) {
        return this.organization.value;
      }
      return null; // Handle the case when organization is not selected
    },
  },
  watch: {
    // asset_group_type: function (newAssetGroupType, oldAssetGroupType) {
    //   if (newAssetGroupType === "internal") {
    //     this.loadnetworkLocation();
    //   }
    // },
  },
  created() {
    this.loadnetworkLocation();
    this.loadcheckFeature();
  },
  methods: {
    // formSubmitted() {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Form Submitted",
    //       icon: "EditIcon",
    //       variant: "success",
    //     },
    //   });
    // },
    // gotoAssetDiscovery(){

    // },
    gotoverficationTabs() {
      this.$refs.verficationTabs.nextTab();
    },
    goToLastTab() {
      const totalTabs = this.$refs.wizard.tabs.length;
      for (let i = 0; i < totalTabs - 1; i++) {
        this.$refs.wizard.nextTab();
      }
    },
    resetField() {
      this.assetURL = "";
      this.asset_name = "";
      this.asset_type = "";
    },

    addAsset() {
      if (!this.is_staff) this.asset_owner_id = this.$store.state.app.user.id;
      this.isLoadingAsset = true;
      let data = {
        asset_name: this.assetURL,
        asset_nickname: this.asset_name,
        asset_type: this.asset_type,
        // asset_type: 2,
        asset_owner_id: this.asset_owner_id,
        asset_grouptype: this.asset_group_type,
      };

      let scanners = [];
      data.scanners = JSON.stringify(scanners);
      if (this.asset_group_type == "internal") {
        data.is_verified = true;
        data.network_location = this.networkLocation;
      }
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/org-asset/",
      };
      this.$http(options).then((res) => {
        // console.log(res);
        if (res.data.asset_id) {
          (this.assetId = res.data.asset_id),
            (this.is_verified = res.data.is_verified),
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Asset Created Successfully",
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "success",
              },
            });
          if (this.is_verified) {
            this.isLoadingAsset = false;
            this.goToLastTab();
          } else {
            this.isLoadingAsset = false;
            this.$refs.wizard.nextTab();
          }
        }
        if (res.data.non_field_errors) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.non_field_errors[0],
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // this.isLoadingAsset = false;
        }
      });
      // this.isLoadingAsset = false;
    },

    addAssetInternal() {
      if (!this.is_staff) this.asset_owner_id = this.$store.state.app.user.id;
      this.isDisable = true;
      this.isLoadingAsset = true;
      let data = {
        asset_name: this.assetURL,
        asset_nickname: this.asset_name,
        asset_type: this.asset_type,
        // asset_type: 2,
        asset_owner_id: this.asset_owner_id,
        asset_grouptype: "internal",
      };

      // let scanners = [];
      // data.scanners = JSON.stringify(scanners);
      // if (this.asset_group_type == "internal") {
      // data.is_verified = true;
      data.network_location = this.networkLocation;
      // }
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/org-asset/",
      };
      this.$http(options)
        .then((res) => {
          // console.log(res);
          if (res.data.asset_id) {
            (this.assetId = res.data.asset_id),
              (this.is_verified = res.data.is_verified),
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Asset Created Successfully",
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            // if (this.is_verified) {
            this.isLoadingAsset = false;
            this.goToLastTab();
            // } else {
            //   this.isLoadingAsset = false;
            //   this.$refs.wizard.nextTab();
            // }
          }
          if (res.data.non_field_errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.non_field_errors[0],
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            this.isLoadingAsset = false;
          }
          this.isDisable = true;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingAsset = false;
          this.isDisable = false;
          // this.resetField();
        });
      // this.isLoadingAsset = false;
    },

    addAssetInternetFacing() {
      if (!this.is_staff) this.asset_owner_id = this.$store.state.app.user.id;
      this.isDisableInternetFacing = true;
      this.isLoadingAssetInternetFacing = true;
      let data = {
        asset_name: this.assetURLExternal,
        asset_nickname: this.asset_name_external,
        asset_type: this.asset_type_external,
        // asset_type: 2,
        asset_owner_id: this.asset_owner_id,
        asset_grouptype: "external",
      };

      // let scanners = [];
      // data.scanners = JSON.stringify(scanners);
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/org-asset/",
      };
      this.$http(options)
        .then((res) => {
          // console.log(res);
          if (res.data.asset_id) {
            (this.assetId = res.data.asset_id),
              (this.is_verified = res.data.is_verified),
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Asset Created Successfully",
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            // if (this.is_verified) {
            this.isLoadingAssetInternetFacing = false;
            this.goToLastTab();
            // } else {
            //   this.isLoadingAssetInternetFacing = false;
            //   this.$refs.wizard.nextTab();
            // }
          }
          if (res.data.non_field_errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.non_field_errors[0],
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            this.isLoadingAssetInternetFacing = false;
          }
          this.isDisableInternetFacing = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingAssetInternetFacing = false;
          this.isDisableInternetFacing = false;
          // this.resetField();
        });
      // this.isLoadingAssetInternetFacing = false;
      // this.isLoadingAsset = false;
    },
    getfileVerification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/file-verification",
      }).then((res) => {
        this.fVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // if (this.is_verified) {
          //   this.goToLastTab();
          // } else {
          //   this.$refs.wizard.nextTab();
          // }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.goToLastTab();
        }
      });
    },
    sendMail() {
      this.disableMail = true;
      this.isLoadMail = true;
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "notification/send-help-mail",
      }).then((res) => {
        this.DNSVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.disableMail = false;
        this.isLoadMail = false;
      });
    },
    getDNSverification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/verify-dns",
      }).then((res) => {
        this.DNSVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.goToLastTab();
        }
      });
    },
    getHTMLverification() {
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.assetId +
          "/html-verification",
      }).then((res) => {
        // this.HTMLVerification = res.data;
        if (res.data.status == "error") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.goToLastTab();
        }
      });
    },
    startTest() {
      (this.disableBtn = true),
        (this.isloading = true),
        this.$http({
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset/" +
            this.assetId +
            "/start-testing",
        }).then((res) => {
          // this.teststart = res.data;
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push(`/assets/scan/${this.assetId}`);

            // this.$router.push({ name: "Assets" });
          }
          this.disableBtn = false;
          this.isloading = false;
        });
    },
    loadcheckFeature() {
      let url =
        process.env.VUE_APP_BASEURL +
        "feature/check-feature?feature_code=internal_scan";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.checkFeature = res.data.is_exist;
      });
    },
    loadnetworkLocation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/network-location/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.networkLocationOptions = res.data.map((item) => ({
          value: item.id,
          text: item.title,
        }));

        // Find the index of the item with text "DEFAULT"
        const defaultIndex = this.networkLocationOptions.findIndex(
          (item) => item.text === "DEFAULT"
        );

        // If "DEFAULT" is found, set it as the default selection
        if (defaultIndex !== -1) {
          this.networkLocation =
            this.networkLocationOptions[defaultIndex].value;
        } else if (this.networkLocationOptions.length > 0) {
          // If "DEFAULT" is not found, fallback to selecting the first item
          this.networkLocation = this.networkLocationOptions[0].value;
        }
      });
    },
    gotoCreateNetwork() {
      this.$refs["modal-new-network-location"].show();
    },
    closeNetworkModal() {
      this.$refs["modal-new-network-location"].hide();
      this.loadnetworkLocation();
    },
  },
};
</script>
<style lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.5; /* or any other styling to indicate disabled state */
}
.mt-10 {
  margin-top: 10rem !important;
}
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
// .form-wizard .wizard-buttons {
//   display: none !important;
// }
[dir="ltr"] .vertical.wizard-vertical.vue-form-wizard .wizard-card-footer {
  display: none !important;
}
</style>

