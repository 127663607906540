<template>
  <b-card title="">
    <b-card class="bg-dark-blue">
      <div class="row">
        <div class="col-12">
          <validation-observer ref="observer" #default="{ invalid }">
            <b-form @submit.prevent>
              <div class="row" v-if="is_staff">
                <div class="col-4">
                  <b-form-group class="my-1">
                    <b-form-select v-model="domain_organization" disabled>
                      <option
                        :value="org"
                        v-for="(org, o) in organizations"
                        :key="o"
                      >
                        {{ org.name }}
                      </option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Domain"
                      rules="required"
                    >
                      <b-form-input
                        class="my-1"
                        id="name"
                        v-model="domain"
                        name="domain"
                        placeholder="Enter your domain"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group class="my-1">
                    <b-form-select
                      v-model="schedule_selected"
                      :options="schedule_data"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-7">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Domain"
                      rules="required"
                    >
                      <b-form-input
                        class="my-1"
                        id="name"
                        v-model="domain"
                        name="domain"
                        placeholder="Enter your domain"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-5">
                  <b-form-group class="my-1">
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Schedule"
                      rules="required"
                    > -->
                    <b-form-select
                      v-model="schedule_selected"
                      :options="schedule_data"
                    ></b-form-select>
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </div>
                <!-- <div class="col-2">
                  <button
                    style=""
                    @click="scanCreate()"
                    type="button"
                    class="btn btn-primary my-1"
                    :disabled="invalid"
                  >
                    <span v-if="isloading"
                      ><b-spinner type="border" small></b-spinner> Please
                      wait</span
                    >
                    <span v-else>New Scan</span>
                  </button>
                </div> -->
              </div>
              <div
                v-if="
                  schedule_selected === 5 ||
                  schedule_selected === 6 ||
                  schedule_selected === 7 ||
                  schedule_selected === 8
                "
              >
                <div class="row">
                  <div class="col-4 mb-1">
                    <flat-pickr
                      placeholder=" Date"
                      style="width: 100%"
                      v-model="scanDate"
                      class="form-control bg-background"
                    />
                  </div>
                  <div class="col-2">
                    <v-select
                      v-model="hours"
                      label="text"
                      placeholder="--Time --"
                      :options="hours_options"
                      autocomplete
                      style="width: 100%"
                      :reduce="(hours) => hours.value"
                    >
                    </v-select>
                  </div>
                  <div class="col-6">
                    <v-select
                      v-model="selectedTimeZone"
                      label="text"
                      placeholder="--Select Time Zone--"
                      :options="timeZones"
                      autocomplete
                      style="width: 100%"
                      :reduce="(selectedTimeZone) => selectedTimeZone.value"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-2 d-flex justify-content-end">
                  <button
                    v-if="schedule_selected === null"
                    style=""
                    @click="scanCreate()"
                    type="button"
                    class="btn btn-primary"
                    :disabled="invalid"
                  >
                    <span v-if="isloading"
                      ><b-spinner type="border" small></b-spinner> Please
                      wait</span
                    >
                    <span v-else>Instant Scan</span>
                  </button>
                  <button
                    v-if="schedule_selected != null"
                    style=""
                    @click="scanCreate()"
                    type="button"
                    class="btn btn-primary"
                    :disabled="invalid"
                  >
                    <span v-if="isloading"
                      ><b-spinner type="border" small></b-spinner> Please
                      wait</span
                    >
                    <span v-else>New Scan</span>
                  </button>
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-card>
    <b-tabs v-model="DomainTabIndex">
      <b-tab title="Scans" Active>
        <DomainScan
          ref="domainScan"
          :agent_types="agent_types"
          :DomainTabIndex="DomainTabIndex"
          :tabIndex="tabIndex"
        />
      </b-tab>
      <b-tab title="Schedule" lazy>
        <DomainSchedule
          ref="domainSchedule"
          :scheduleorganization="selectedOrganizationRefid"
          :agent_types="agent_types"
          :DomainTabIndex="DomainTabIndex"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
  BFormSelectOption,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssetsDiscover from "./components/assets_discovered.vue";
// import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DomainScan from "../Scan/amassScan.vue";
import DomainSchedule from "../Scan/schedule/amassSchedule.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment-timezone";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    DomainScan,
    DomainSchedule,
    BFormSelectOption,
    flatPickr,
    vSelect,
  },
  props: {
    agent_types: {
      type: Array,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      // Call orgaization API and return in this format
      scan_id: 0,
      selected_group: 0,
      is_staff: this.$store.state.app.user.is_staff,
      super_admin: this.$store.state.app.user.id == 1,
      // organization: null,
      scheduleorganization: null,
      // domain_organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,s
      // domain_organization: this.$store.state.app.org_id,
      // domain_organization: null,
      selectedOrganization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organizations: [{ value: null, text: "--Select Organization--" }],
      name_filter: "",
      domain: "",
      loading: false,
      schedule_selected: null,
      schedule_data: [
        { value: null, text: "--Select Schedule--" },
        { value: 5, text: "Once in a month" },
        { value: 6, text: "Once in a three-month" },
        { value: 7, text: "Once in a six-month" },
        { value: 8, text: "Once in a year" },
      ],
      hours_options: [
        { value: "01:00:00", text: "01:00" },
        { value: "02:00:00", text: "02:00" },
        { value: "03:00:00", text: "03:00" },
        { value: "04:00:00", text: "04:00" },
        { value: "05:00:00", text: "05:00" },
        { value: "06:00:00", text: "06:00" },
        { value: "07:00:00", text: "07:00" },
        { value: "08:00:00", text: "08:00" },
        { value: "09:00:00", text: "09:00" },
        { value: "10:00:00", text: "10:00" },
        { value: "11:00:00", text: "11:00" },
        { value: "12:00:00", text: "12:00" },
        { value: "13:00:00", text: "13:00" },
        { value: "14:00:00", text: "14:00" },
        { value: "15:00:00", text: "15:00" },
        { value: "16:00:00", text: "16:00" },
        { value: "17:00:00", text: "17:00" },
        { value: "18:00:00", text: "18:00" },
        { value: "19:00:00", text: "19:00" },
        { value: "20:00:00", text: "20:00" },
        { value: "21:00:00", text: "21:00" },
        { value: "22:00:00", text: "22:00" },
        { value: "23:00:00", text: "23:00" },
        { value: "00:00:00", text: "24:00" },
      ],
      hours: "",
      scanDate: "",
      isloading: false,
      timeZones: [],
      selectedTimeZone: null,
      agent_id: null,
      DomainTabIndex: 0,
    };
  },
  computed: {
    domain_organization() {
      return this.organizations.find(
        (org) => org.tenant_uid === this.$store.state.app.org_id
      );
    },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.domain_organization) {
        return this.domain_organization.tenant_uid;
      }
      return null;
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.domain_organization) {
        return this.domain_organization.id;
      }
      return null; // Handle the case when organization is not selected
    },
  },
  mounted: function () {
    // this.loadOrg();
    this.populateTimeZones();
    this.setDefaultTimeZone();
    if (this.DomainTabIndex === 0) {
      this.$refs.domainScan.load();
    }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    populateTimeZones() {
      this.timeZones = moment.tz
        .names()
        .filter((tz) => tz !== "Asia/Calcutta")
        .map((tz) => ({ text: tz, value: tz }));
      // this.timeZones = moment.tz.names();
    },
    setDefaultTimeZone() {
      const localTimeZone = moment.tz.guess();
      const updatedTimeZone =
        localTimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : localTimeZone;
      this.selectedTimeZone = updatedTimeZone;
    },
    // loadOrg: function () {
    //   if (this.$route.query.page) this.currentPage = this.$route.query.page;
    //   if (this.is_staff) {
    //     this.loading = true;

    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {

    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].org_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //       this.loading = false;
    //     });
    //   } else {
    //     this.organization = this.$store.state.app.organizationId;

    //   }

    // },

    assets_discovered: function (id) {
      this.scan_id = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    scanCreate() {
      const selectedAgentType = this.agent_types.find(
        (item) => item.agent_type_code === "amass"
      );
      this.agent_id = selectedAgentType
        ? selectedAgentType.agent_type_id
        : null;
      if (this.schedule_selected == null) {
        this.isloading = true;
        let data = {
          org_id: this.selectedOrganizationId,
          domain: this.domain,
          agent_type: this.agent_id,
          scan_created_by: this.userId,
        };
        const options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset-discovery/scan/",
        };
        var self = this;
        this.$http(options)
          .then((res) => {
            console.log(res, " scan response");
            if (res.data.scan_id) {
              this.domain = "";
              this.$nextTick(() => this.$refs.observer.reset());
              // this.$refs.domain.$refs.reset();
              this.$refs.domainScan.load();
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Scan Successfully created",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              // self.load();
            }
            this.isloading = false;
          })
          .catch((err) => {
            this.isloading = false;
          });
      } else {
        // console.log(this.schedule_selected, "else part here");
        this.isloading = true;
        // if (!this.is_staff)
        //   this.domain_organization = this.$store.state.app.org_id;
        let data = {
          agent_type: this.agent_id,
          domain: this.domain,
          org_id: this.selectedOrganizationId,
          // is_scan_scheduled: 1,
          is_scan_scheduled: true,
          // scan_scheduled_type: this.schedule_selected,
          scan_schedule_type: this.schedule_selected,
          scan_schedule_time: this.hours,
          timezone: this.selectedTimeZone,
          scan_schedule_date: this.scanDate,
        };
        const options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset-discovery/schedule/",
        };
        var self = this;
        this.$http(options)
          .then((res) => {
            console.log(res.data, " scan schedule response");
            if (res.data.org_id) {
              self.domain = "";
              self.schedule_selected = null;
              self.$nextTick(() => this.$refs.observer.reset());
              // self.$refs.domainSchedule.SchedulesearchFn();
              // self.loadSchedules();
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Schedule Successfully created",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
            }
          })
          .catch((err) => {
            this.isloading = false;
          });
      }
      // this.isloading = false;
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #131721 !important;
  color: #fff;
}
</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  // background: #283046 !important;
  background: #253e42 !important;
  border-color: #404656;
}
</style>
