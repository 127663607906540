<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <validation-observer ref="GroupAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addGroup">
        <!-- Organization-->
        <b-form-group
          v-if="is_staff"
          label="Organization"
          label-for="Organization"
        >
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <v-select
              v-model="organization"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              @input="onOrgChange"
              autocomplete
              :reduce="(organization) => organization.tenant_uid"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Owner-->
        <b-form-group v-if="is_staff" label="Owner" label-for="Owner">
          <template #label>
            <span>Owner</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="group_owner_id"
            rules="required"
          >
            <b-form-select
              v-model="group_owner_id"
              :options="users"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Asset Name -->
        <b-form-group label="Name" label-for="Name">
          <template #label>
            <span>Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Group Name"
            :rules="{ regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ \-]+$/ }"
          >
            <b-form-input
              id="group_name"
              v-model="group_name"
              :state="errors.length > 0 ? false : null"
              name="group_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Asset Nick Name -->
        <b-form-group label="Group Description" label-for="Group Description">
          <validation-provider
            #default="{ errors }"
            name="Group Description"
            :rules="{ regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ \-]+$/ }"
          >
            <b-form-textarea
              id="group_description"
              v-model="group_description"
              name="group_description"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || group_name === '' || disable"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      group_name: "",
      group_owner_id: "",
      group_description: "",
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organizations: [],
      users: [{ value: null, text: "--Select Owner--" }],
      isloading: false,
      disable: false,
    };
  },
  props: {
    is_asset_group: {
      type: Boolean,
      required: true,
    },
    parentCallBack: Function,
  },
  created: function () {
    // this.load();
    this.onOrgChange();
  },
  methods: {
    // load: function () {
    //   if (this.is_staff) {
    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org/",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {

    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].sso_ref_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //     });
    //   }
    // },
    onOrgChange: function () {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text:
              res.data[key].full_name.charAt(0).toUpperCase() +
              res.data[key].full_name.slice(1),
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });
    },
    addGroup: function () {
      this.$refs.GroupAddForm.validate().then((success) => {
        this.isloading = true;
        this.disable = true;
        if (success) {
          //call to axios
          var self = this;
          if (!this.is_staff)
            this.group_owner_id = this.$store.state.app.user.id;
          let data = {
            group_name: this.group_name,
            group_description: this.group_description,
            group_owner_id: this.group_owner_id,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "asset/group/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.group_id) {
              if (!this.is_asset_group) {
                this.$router.push({
                  name: "Group Assets",
                  params: { id: res.data.group_id },
                });
              } else {
                this.parentCallBack();
              }
              // this.$router.push({name: 'Groups'});
              self.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Successfully added group! You may now add assets to your group!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
              this.disable = false;
            }
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>