

<template>
  <div>
    <div
      v-if="!redirectNetwork"
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <div class="m-1">
      <validation-observer ref="NetworkAddForm" #default="{ invalid }">
        <b-form class="auth-login-form" @submit.prevent="CreateNetwork()">
          <!-- network_name Name -->
          <b-form-group label="Network Location Name" label-for="Name">
            <template #label>
              <span>Network Location Name</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Network Name"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
              }"
            >
              <b-form-input
                id="network_name_name"
                v-model="networkName"
                :state="errors.length > 0 ? false : null"
                name="network_name_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Add</span>
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
  },
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    redirectNetwork: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      networkName: "",
      isloading: false,
      redirectNetwork: false,
    };
  },
  methods: {
    CreateNetwork: function () {
      this.$refs.NetworkAddForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          var self = this;
          let data = {
            title: this.networkName,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "agent/network-location/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.closeSidebar();
          });
          this.isloading = false;
          this.networkName = "";
          this.$refs.NetworkAddForm.reset();
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>