var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.redirectNetwork)?_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.closeSidebar()}}})],1)]):_vm._e(),_c('div',{staticClass:"m-1"},[_c('validation-observer',{ref:"NetworkAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.CreateNetwork()}}},[_c('b-form-group',{attrs:{"label":"Network Location Name","label-for":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Network Location Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Network Name","rules":{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"network_name_name","state":errors.length > 0 ? false : null,"name":"network_name_name"},model:{value:(_vm.networkName),callback:function ($$v) {_vm.networkName=$$v},expression:"networkName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Add")])])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }